const uz = {
  Add: "Qo'shish",
  IsStatic: "Statik",
  HasCategory: "Kategoriya",
  Delete: "O'chirish",
  Edit: "Tahrirlash",
  goToSite: "Saytga o'tish",
  Link: "Havola",
  Type: "Turi",
  SelectType: "Turni tanlang",
  Published: "Chop etilgan",
  NotPublished: "Chop etilmagan",
  UsersColTitle: "Login",
  UserTitle: "Foydalanuvchilar",
  FormFullName: "F.I.Sh",
  FormFirstName: "Ism",
  FormFirstNamePlace: "Ismingizni kiriting",
  FormLastName: "Familiya",
  FormLastNamePlace: "Familiyangizni kiriting",
  FormMiddleName: "Sharif",
  FormMiddleNamePlace: "Sharifingizni kiriting",
  FormBirthDate: "Tug‘ilgan yil",
  FormGender: "Jinsi",
  FormMale: "Erkak",
  FormFemale: "Ayol",
  FormUsername: "Foydalanuvchi nomi",
  FormUsernamePlace: "Foydalanuvchi nomini kiriting",
  FormPassword: "Parol",
  FormPasswordPlace: "Parolingizni kiriting",
  FormPhone: "Telefon raqami",
  FormInnerNum: "Ichki raqam",
  FormPhoneInfoService: "Axborot xizmati",
  FormPhoneHelp: "Ishonch telefoni",
  FormPhoneContact: "Murojaat uchun telefon",
  FormPhoneInfoServicePlace: "Axborot xizmati raqamini kiriting",
  FormchancelleryPlace: "Kanselyariya kiriting",
  FormDepartment: "Nazorat bo'limi kiriting",
  FormProfession: "Professiya",
  FormProfessionPlace: "Professiya",
  FormRole: "Rol",
  FormBirthPlace: "Tug'ilgan joy",
  FormBirthPlacePlaceholder: "Tug'ilgan joy kiriting",
  FormWorkPlace: "Ish joyi",
  FormWorkPlacePlaceholder: "Ish Joyini kiriting",
  FormNat: "Millat",
  FormNatPlace: "Millatingizni kiriting",
  FormEdu: "Ta'lim",
  FormEduPlace: "Ta'limingizni kiriting",
  FormUni: "O'qish joyi",
  FormUniPlace: "O'qish joyini kiriting",
  FormSpec: "Mutaxassislik",
  FormSpecPlace: "Mutaxassislikni kiriting",
  FormAcademDeg: "Ilmiy daraja",
  FormAcademDegPlace: "Ilmiy darajani kiriting",
  FormAcademtit: "Ilmiy unvon",
  FormAcademtitPlace: "Ilmiy unvonni kiriting",
  FormBodies: "Saylangan davlat organlari",
  FormOkrug: "Okrug",
  FormParty: "Partiya",
  FormPartyPlace: "Partiyani kiriting",
  FormFraction: "Fraktsiya",
  FormCommittee: "Qo'mita",
  // Menu
  MenuTitle: "Menyu nomi:",
  MenuTitlePlace: "Menyu nomini kiriting",
  MenuGroup: "Gruppa:",
  MenuGroupPlace: "Gruppani kiriting",
  MenuParentName: "Menyuni tanlang:",
  MenuPosition: "Pozitsiya",
  MenIsBlog: "Blog",
  // Tags
  TagsColTitle: "Nomi",
  TagsColNumber: "Raqami",
  // Table
  TableTitle: "Nomi",
  TableContent: "Tarkib",
  TableShortContent: "Qisqa tarkib",
  TablePhoto: "Rasm",
  TablePhotos: "Rasmlar",
  TableDoc: "Hujjatlar",
  TableHead: "Sarlavha",
  TableDate: "Nashr qilingan sana",
  TableCreatedDate: "Yaratilgan sana",
  TableLastUpdatedDate: "Yangilangan sana",
  TableAction: "Xarakat",
  Lang: "Tillar",
  Content: "Tarkib",
  IsActive: "Faolmi ?",
  Active: "Faol",
  Cancel: "Bekor qilish",
  Save: "Saqlash",
  SaveAndExit: "Saqlash va chiqish",
  FileDoc: "Hujjatlar fayllari",
  FileSelect: "faylni tanlang",
  LangContent: "Tarkib tillari",
  LangTitle: "Sarlavha tillari",
  File: "Fayl",
  Created: "Yaratilgan",
  Create: "Yaratmoq",
  AdressPlac: "Manzilni kiriting",
  Source: "Manba",
  SourcePlac: "Manbani kiriting",
  Deputy: "Deputat",
  Video: "Video",
  selected: "tanlangan",
  InputText: "Matnni kiriting",
  Leader: "Rahbar",
  // Committe
  CommitTitle: "Qo'mita nomi",
  CommitTitlePlac: "Komissiya nomini kiriting",
  CommitEdit: "Qo'mitani tahrirlash",
  Chairman: "Rais",
  SubChairman: "Rais o'rinbosari",
  Status: "Status",
  Url: "URL manzili",
  EnterTheTitle: "Nomini kiriting",
  EnterStatus: "Statusni kiriting",
  EnterUrl: "URL manzilni kiriting",
  ListBroadcast: "Jonli efirlar ro'yxati bo'sh!",
  LawsEmpty: "Qonunlar ro'yxati bo'sh!",
  TranslateCopyTo: "ga tarjima qilish",
  EnterTitle: "Sarlavha kiriting",
  DateOfPublication: "Nashr qilingan sana",
  Close: "Yopish",
  AreasEmpty: "Hududlar ro'yxati bo'sh!",
  AddArea: "Maydon qo'shish",
  Region: "Hudud",
  CountiesEmpty: "Viloyatlar ro'yxati bo'sh!",
  AddCommissions: "Komissiyalar qo'shing",
  Loading: "Yuklanmoqda...",
  UploadDocToServer: "Hujjatlarni serverga yuklash",
  Downloads: "Yuklamalar",
  Consider: "O'ylab ko'ring",
  ToAnswer: "Javob berish",
  ViewAnswer: "Javobni ko'rish",
  Adress: "Manzil",
  EnterAdress: "Manzilni kiriting",
  SenderStatus: "Yuboruvchi xolati",
  ToWhom: "Kimga",
  Passport: "Pasport",
  PassportNumber: "Pasport raqami",
  LastUpdateDate:"Oxirgi yangilanish sanasi",
  Chancery: "Devonxona",
  EnterChanceryNumb: "Devonxona raqamini kiriting",
  Faks: "Faks",
  EnterFaks: "Faksni kiriting",
  Email: "Pochta manzili",
  EnterEmail: "Pochta manzilingizni kiriting",
  Bus: "Avtobuslar",
  EnterBuses: "Avtobus raqamini kiriting",
  MiniBus: "Marshrutlar",
  EnterMinibuses: "Marshrut raqamini kiriting",
  Metro: "Metro",
  EnterMetro: "Metro bekatini kiriting",
  ReferencePoint: "Mo'ljal",
  EnterLandmark: "Mo'ljalni kiriting",
  WorkingTime: "Ish vaqti",
  Sum: "Narx",
  Value: "Qiymat",
  Logo: "Logotip",
  Icon: "Ikonka",
  Question: "Savol",
  Questions: "Savollar",
  QuestionTheme: "Savol mavzusi",
  Answer: "Javob",
  Author: "Muallif",
  ViewsCount: "Ko'rishlar soni",
  OnSlider: "Asosiyga",
  Comments: "Izohlar",
  JobTitle: "Lavozim",
  Department: "Departament",
  HasExpired: "Muddati tugagan",
  AcceptDate: "Qabul qilingan sana",
  Order: "Tartib",
  Activity: "Faoliyati",
  pinfl: "Shaxsiy Identifikatsiya Raqami (PINFL)",
  cert_number: "Sertifikat raqami",
  licence_number:"Guvohnoma, raqami",
  cert_registered_date:"Sertifikat ro'yxatga olingan sana",
  cert_validity_date: "Sertifikat amal qilish muddati",
  licence_registered_date: "Guvohnoma ro'yxatga olingan sana",
  projectRegistry:"Davlat-xususiy sheriklik loyihalari reyestri", 
}

export default uz
