const en = {
  Add: "Add",
  IsStatic: "Static",
  HasCategory: "Category",
  Delete: "Delete",
  Edit: "Edit",
  goToSite: "Go to website",
  Link: "Link",
  Type: "Type",
  SelectType: "Type",
  Published: "Published",
  NotPublished: "Not published",
  UsersColTitle: "Login",
  UserTitle: "Users",
  FormFullName: "Full name",
  FormFirstName: "First name",
  FormFirstNamePlace: "Enter your first name",
  FormLastName: "Last name",
  FormLastNamePlace: "Enter your last name",
  FormMiddleName: "Middle name",
  FormMiddleNamePlace: "Enter your middle name",
  FormBirthDate: "Birth date",
  FormGender: "Gender",
  FormMale: "Male",
  FormFemale: "Female",
  FormUsername: "Username",
  FormUsernamePlace: "Enter your username",
  FormPassword: "Password",
  FormPasswordPlace: "Enter your password",
  FormPhone: "Phone number",
  FormInnerNum: "Inner number",
  FormPhoneInfoService: "Information service",
  FormPhoneHelp: "Phone Helpline",
  FormPhoneContact: "Contact phone number",
  FormPhoneInfoServicePlace: "Enter information service phone number",
  FormchancelleryPlace: "Enter chancellery",
  FormDepartment: "Enter Control Department",
  FormProfession: "Profession",
  FormProfessionPlace: "Enter your profession",
  FormRole: "Role",
  FormBirthPlace: "Birth place",
  FormBirthPlacePlaceholder: "Enter your birth place",
  FormWorkPlace: "Work place",
  FormWorkPlacePlaceholder: "Enter your work place",
  FormNat: "Nationality",
  FormNatPlace: "Enter your nationality",
  FormEdu: "Education",
  FormEduPlace: "Enter your education",
  FormUni: "University",
  FormUniPlace: "Enter your university",
  FormSpec: "Speciality",
  FormSpecPlace: "Enter your speciality",
  FormAcademDeg: "Academic degree",
  FormAcademDegPlace: "Enter your academic degree",
  FormAcademtit: "Academic title",
  FormAcademtitPlace: "Enter your academic title",
  FormBodies: "Elected government bodies",
  FormOkrug: "Okrug",
  FormParty: "Party",
  FormPartyPlace: "Enter your Party",
  FormFraction: "Fraction",
  FormCommittee: "Committee",
  // Menu
  MenuTitle: "Menu name:",
  MenuTitlePlace: "Enter menu name",
  MenuGroup: "Group:",
  MenuGroupPlace: "Enter group",
  MenuParentName: "Select the parent menu:",
  MenuPosition: "Position",
  MenIsBlog: "Blog",
  // Tags
  TagsColTitle: "Title",
  TagsColNumber: "Number",
  // Table
  TableTitle: "Title",
  TableContent: "Content",
  TableShortContent: "Short content",
  TablePhoto: "Photo",
  TablePhotos: "Images",
  TableDoc: "Documents",
  TableHead: "Heading",
  TableDate: "Date of publication",
  TableCreatedDate: "Created date",
  TableLastUpdatedDate: "Updated date",
  TableAction: "Action",
  Lang: "Languages",
  Content: "Content",
  IsActive: "Is active",
  Active: "Active",
  Cancel: "Cancellation",
  Save: "Save",
  SaveAndExit: "Save and exit",
  FileDoc: "Files documents",
  FileSelect: "Select a file",
  LangContent: "Content languages",
  LangTitle: "Title languages",
  File: "File",
  Created: "Created",
  Create: "Create",
  AdressPlac: "Enter the address",
  Source: "Source",
  SourcePlac: "Enter source",
  Deputy: "Deputy",
  Video: "Video",
  selected: "selected",
  InputText: "Input text here",
  Leader: "Leader",
  // Committe
  CommitTitle: "Committee name",
  CommitTitlePlac: "Enter the name of the committee",
  CommitEdit: "Edit committee",
  Chairman: "Chairman",
  SubChairman: "Sub chairman",
  Status: "Status",
  Url: "URL adress",
  EnterTheTitle: "Enter The Title",
  EnterStatus: "Enter Status",
  EnterUrl: "Enter URL adress",
  ListBroadcast: "The list of live broadcasts is empty!",
  LawsEmpty: "The list of laws is empty!",
  TranslateCopyTo: "translate copy to",
  EnterTitle: "Enter title",
  DateOfPublication: "Date of publication",
  Close: "Close",
  AreasEmpty: "The list of areas is empty!",
  AddArea: "Add area",
  Region: "Region",
  CountiesEmpty: "The list of counties is empty!",
  AddCommissions: "Add commissions",
  Loading: "Loading...",
  UploadDocToServer: "Upload documents to the server",
  Downloads: "Downloads",
  Consider: "Consider",
  ToAnswer: "Answer",
  ViewAnswer: "View answer",
  Adress: "Adress",
  EnterAdress: "Enter adress",
  SenderStatus: "Sender status",
  ToWhom: "To whom",
  Passport: "Passport",
  PassportNumber: "Passport Number",
  LastUpdateDate:"Last update date",
  Chancery: "Chancery",
  EnterChanceryNumb: "Enter the office number",
  Faks: "Faks",
  EnterFaks: "Enter your faks",
  Email: "Email",
  EnterEmail: "Enter your email",
  Bus: "Buses",
  EnterBuses: "Enter bus numbers",
  MiniBus: "Minibuses",
  EnterMinibuses: "Enter the numbers of minibuses",
  Metro: "Metro",
  EnterMetro: "Enter the metro station",
  ReferencePoint: "Reference point",
  EnterLandmark: "Enter a landmark",
  WorkingTime: "Working time",
  Sum: "Sum",
  Value: "Value",
  Logo: "Logo",
  Icon: "Icon",
  Question: "Question",
  Questions: "Questions",
  QuestionTheme: "Question theme",
  Answer: "Answer",
  Author: "Author",
  ViewsCount: "Views count",
  OnSlider: "To the main",
  Comments: "Comments",
  JobTitle: "Job title",
  Department: "Department",
  HasExpired: "Has expired",
  AcceptDate: "Accepted date",
  Order: "Order",
  Activity: "Activity",
  pinfl: "Personal Identification Number (PIN)",
  cert_number: "Certificate number",
  licence_number:"License Number",
  cert_registered_date:"Certificate Registered Date",
  cert_validity_date: "Certificate Validity Date",
  licence_registered_date: "License Validity Date",
  projectRegistry:"The register PPP projects",
}

export default en
